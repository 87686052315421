.__area {
    font-family: 'Cairo', sans-serif;
    color: #7c7671;
    margin-top: 100px
  }
  
  .__card {
    max-width: 350px;
    margin: auto;
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: unset;
    text-decoration: none !important;
  
  }
  .ali{
    box-shadow: 4px 4px 4px 4px #fc9d52;
  }
  .__card:hover {
    color: unset;
    text-decoration: none;
    
  }
  .__img {
    border-radius: 10px;
  }
  
  h1 {
    color: white;
    text-shadow: 3px 3px 8px #dc3131;
  }
  .__favorit {
    background-color: #fff;
    border-radius: 8px;
    color: #fc9d52;
    position: absolute;
    right: 15px;
    top: 8px;
    padding: 3px 4px; 
    font-size: 22px;
    line-height: 100%;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    z-index: 1;
    border: 0;
  }
  .__favorit:hover {
    background-color: #fc9d52;
    color: #fff;
   
  }
  .__card_detail {
    box-shadow: 0 4px 15px rgba(175,77,0,0.13);
    padding: 13px;
    border-radius: 8px;
    margin: -30px 10px 0;
    position: relative;
    z-index: 2;
    background-color: #fff; 
   
  }
  
    @media (max-width: 768px) {
      .responsive-span {
        display: none;
      }
    }
  .__card_detail h4 {
    color: #474340;
    line-height: 100%;
    font-weight: bold;
    /* text-decoration: none !important; */
    text-shadow: 0 0 5px rgba(198, 9, 9, 0.3);
  }
  .__card_detail p {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  .__type span {
    background-color: #feefe3;
    padding: 5px 10px 7px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    color: #fc9d52;
    font-weight: bold;
    line-height: 100%;
  }
  .__detail {
    margin-top: 5px;
  }
  .__detail i {
    font-size: 21px;
    display: inline-block;
    vertical-align: middle;
  }
  .__detail i:nth-child(3) {
    margin-left: 15px;
  }
  .__detail span {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
  }