@media (max-width: 768px) {
    .responsive-span {
      display: none;
    }
  }

  /* Show button only on small screens */
@media (max-width: 768px) {
  .dropbtn {
    display: block;
  }
}

/* Hide button on large screens */
@media (min-width: 769px) {
  .dropbtn {
    display: none;
  }
}

.dropbtn .icon {
  font-size: 1rem; /* Adjust the size here */
}

.dropbtn {
  background-color: #ff0000;
  padding: 15px 0; /* Adjust vertical padding */
  width: 340px; /* Set a larger fixed width */
  font-size: 1.5rem;
  font-weight: 250px;
  color: white;
  border: none;
  text-align: center;
  
  /* Centering styles */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-0%, -175%); /* Center fully */
  
  /* Prevent text wrapping */
  white-space: nowrap;
}




  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000000;
    min-width: 360px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 0px;
    text-decoration: none;
    display: block;
  }
  
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  