/***********************************************
=>   inspired from this video :https://pin.it/7kzImJa

=>   Don't ❌Copy without permisson .
=>   This animation is show a order placed by truck. 
=>   This is my first animation.🙂
*************************************************/

.body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: #e47c34;
  z-index: -6;
}

.contanier {
  position: relative;
  display: flex;
  text-align: center;
  top: 100px;
  left: 100px;
}

.road {
  position: absolute;
  width: 350px;
  height: 100px;
  background-color: #2f363f;
  border-radius: 50px;
  z-index: -3;
  overflow: hidden;
}

ul {
  position: absolute;
  list-style-type: none;
  top: 25px;
  left: -25px;
  z-index: -2;
}

li {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 6px;
  border-radius: 20px;
  background-color: #fff;
  margin-left: 5px;
  animation: new 9s infinite linear;
}

@keyframes new {
  0%,
  40% {
    opacity: 1;
    left: 350px;
  }

  50% {
    left: 0px;
  }

  75% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.bus {
  position: absolute;
  animation: move 9s infinite 0s linear;
  right: 110px;
}

@keyframes move {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  15% {
    transform: translateX(300px);
    opacity: 1;
  }

  25% {
    transform: translateX(-40px);
  }

  49% {
    transform: translateX(-40px);
  }

  55% {
    transform: translateX(10px);
  }

  65% {
    transform: translateX(-65px);
  }

  100% {
    transform: translateX(450px);
  }
}

.back {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 100px;
  height: 60px;
  background-color: #dae0e2;
  border-radius: 1px;
}

.back1door {
  position: absolute;
  top: 5px;
  right: 100px;
  width: 25px;
  height: 5px;
  background-color: #dae0e2;
  animation: 10s linear infinite;
  transform-origin: 130% 130%;
  transform: rotateZ(-90deg);
  animation: move1 9s infinite 1.1s linear;
}

@keyframes move1 {
  0% {
  }

  15% {
    transform: rotate(-90deg);
  }

  20% {
    transform: rotate(25deg);
  }

  23% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-90deg);
  }
}

.back2door {
  position: absolute;
  top: 45px;
  right: 100px;
  width: 25px;
  height: 5px;
  background-color: #dae0e2;
  animation: 10s linear infinite;
  transform-origin: 100% 100%;
  transform: rotateZ(90deg);
  animation: move2 9s infinite 1s linear;
}

@keyframes move2 {
  0% {
  }

  15% {
    transform: rotate(90deg);
  }

  20% {
    transform: rotate(-25deg);
  }

  23% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(90deg);
  }
}

.front {
  position: absolute;
  top: 23px;
  right: -54px;
  width: 50px;
  height: 55px;
  background-color: #ea425c;
  border-radius: 0 10px 10px 0;
  z-index: -2;
}

.front::after {
  position: absolute;
  content: "";
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 33px;
  height: 100%;
  background-color: #b83227;
  border-radius: 0 10px 10px 0;
  z-index: -1;
}

.black {
  position: absolute;
  margin: 0 17px;
  top: 2px;
  width: 15px;
  height: 50px;
  background-color: #000;
  border-radius: 0 30px 30px 0;
}

.black::after {
  position: absolute;
  content: "";
  top: 10px;
  width: 100%;
  height: 5px;
  transform: skew(-10deg);
  background-color: #a4b0bd;
  opacity: 0.3;
}

.black::before {
  position: absolute;
  content: "";
  top: 20px;
  width: 100%;
  height: 5px;
  transform: skew(-10deg);
  background-color: #a4b0bd;
  opacity: 0.3;
}

.join {
  position: absolute;
  top: 15px;
  right: -10px;
  background-color: #dae0e2;
  width: 20px;
  height: 30px;
  z-index: -3;
}

.light1 {
  position: absolute;
  top: 2px;
  right: 0px;
  width: 5px;
  height: 15px;
  background-color: #f3b431;
  border-radius: 10px;
}

.light1::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 5px;
  width: 0px;
  height: 15px;
  background-color: #e1da00;
  opacity: 0.5;
  box-shadow: 5px 0px 5px 1px #e1da00, 10px 0px 5px 2px #e1da00,
    15px 0px 5px 3px #e1da00, 20px 0px 5px 4px #e1da00, 25px 0px 6px 5px #e1da00,
    28px 0px 7px 6px #e1da00;
  border-radius: 10px;
  opacity: 0.8;
}

.light2 {
  position: absolute;
  top: 38px;
  right: 0px;
  width: 5px;
  height: 15px;
  background-color: #f3b431;
  border-radius: 10px;
}

.light2::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 5px;
  width: 0px;
  height: 15px;
  background-color: #e1da00;
  opacity: 0.5;
  box-shadow: 5px 0px 5px 1px #e1da00, 10px 0px 5px 2px #e1da00,
    15px 0px 5px 3px #e1da00, 20px 0px 5px 4px #e1da00, 25px 0px 6px 5px #e1da00,
    28px 0px 7px 6px #e1da00;
  border-radius: 10px;
  opacity: 0.8;
}

.gift {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 35px;
  left: 0px;
  background-color: #fad02e;
  border-radius: 10px;
  overflow: hidden;
  animation: giftan 9s infinite ease-in-out;
}

.gift::after {
  position: absolute;
  content: "";
  top: 16px;
  left: 0;
  right: 0;
  width: 35px;
  height: 3px;
  background-color: #616c6f;
  opacity: 0.8;
  border-radius: 1px;
  z-index: 1;
}

@keyframes giftan {
  0% {
    transform: scale(1);
  }

  10% {
    transform: translateX(20px);
  }

  30% {
    transform: translateX(20px);
  }

  36% {
    transform: scale(1) translateX(120px);
    opacity: 1;
    z-index: -1;
  }

  37% {
    opacity: 0;
  }

  100% {
    transform: scale(0) translateX(350px);
    opacity: 0;
  }
}

.text {
  position: absolute;
  margin: 30px;
  left: 40px;
  font-size: 1.5em;
  display: flex;
  color: #f3b431;
  font-style: bolder;
  animation: show2 9s infinite;
}

@keyframes show2 {
  0%,
  80% {
    opacity: 0;
  }
}
