/* For larger screens */
.khuram {
    margin-left: 300px;
    white-space: nowrap; /* Prevents the text from wrapping */
  }
  
  
  .al{
    margin-left: 250px !important;
    white-space: nowrap;
  }
  /* For smaller screens (up to 768px) */
/* For larger screens */

  
  /* For smaller screens (max-width: 768px) */
  @media (max-width: 768px) {
    .khuram {
      display: flex !important; /* Flexbox to align elements on the same line */
      margin-left: -10px; /* Negative margin to shift to the left */
      gap: 7px !important; /* Removes space between elements */
      align-items: center !important; /* Centers items vertically if needed */
      justify-content: flex-start !important;
    }
  }
  
  